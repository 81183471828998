/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =IVF Homepage
----------------------------------------------------------*/
/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/
/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/
/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.stretchy {
  width: 100%;
  font-size: 0px; }

.float-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  float: left; }

.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

#CallCTA,
#first,
#at-risk,
#know,
#last {
  float: none;
  clear: both; }

#CallCTA {
  display: block;
  width: 100%;
  min-height: 98px;
  background-color: #A01B1F; }

#at-risk {
  padding-top: 20px; }

.section-inner {
  max-width: 979px;
  margin: 0 auto; }
