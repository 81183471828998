$wcmc-base: "../";

/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/

$wcmc-base: "../../../../../profiles/wcmc/themes/wcm_brand_base/";

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

$image-location: $wcmc-base + "images/";

/* =IVF Homepage
----------------------------------------------------------*/
/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/
/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/
/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stretchy {
  width: 100%;
  font-size: 0px;
}

.float-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  float: left;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

#CallCTA,
#first,
#at-risk,
#know,
#last {
  float: none;
  clear: both;
}

#CallCTA {
  display: block;
  width: 100%;
  min-height: 98px;
  background-color: #A01B1F;
}

#at-risk {
  padding-top: 20px;
}

.section-inner {
  max-width: 979px;
  margin: 0 auto;
}
